<template>
  <section>
    <EstruturaAvaliacaoComponent :items="minhasAvaliacoes" />
  </section>
</template>

<script>
import EstruturaAvaliacaoComponent from "./components/EstruturaAvaliacaoComponent.vue";

export default {
  name: "MinhasAvaliacoes",
  components: {
    EstruturaAvaliacaoComponent,
  },

  created() {
    this.$store.dispatch("avd/buscarPainelMinhasAvaliacoes");
  },

  computed: {
    minhasAvaliacoes: {
      get() {
        return this.$store.getters[
          "avd/getPainelMinhasAvaliacoes"
        ];
      },
    },
  },
};
</script>

<style></style>
